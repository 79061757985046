const LOCAL_SERVER_URL = "http://192.168.1.22:5000/";
const LOCAL_INIT_NAME = "Paul";
const LOCAL_INI_ROOM = "Musique";
const LOCAL_USER_WRITING_TIMEOUT = 1000;
const LOCAL_NOTIFICATION_TIMEOUT = 3000;

const REMOTE_SERVER_URL = "https://lit-refuge-33460.herokuapp.com/";
const REMOTE_INIT_NAME = "";
const REMOTE_INI_ROOM = "";
const REMOTE_USER_WRITING_TIMEOUT = 1000;
const REMOTE_NOTIFICATION_TIMEOUT = 3000;

const localhost = window.location.host.indexOf("3000") !== -1;
console.log(window.location.host);
console.log("localhost", localhost);

let gl;
if (localhost) {
  gl = {
    serverURL: LOCAL_SERVER_URL,
    initName: LOCAL_INIT_NAME,
    initRoom: LOCAL_INI_ROOM,
    userWritingTimeout: LOCAL_USER_WRITING_TIMEOUT,
    notificationTimeout: LOCAL_NOTIFICATION_TIMEOUT,
  };
} else {
  gl = {
    serverURL: REMOTE_SERVER_URL,
    initName: REMOTE_INIT_NAME,
    initRoom: REMOTE_INI_ROOM,
    userWritingTimeout: REMOTE_USER_WRITING_TIMEOUT,
    notificationTimeout: REMOTE_NOTIFICATION_TIMEOUT,
  };
}

console.log("gl", gl);

export { gl };
