import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Join from "./components/Join/Join";
import JoinOrChat from "./JoinOrChat";

const App = () => {
  return (
    <Router>
      <Route path="/" exact component={Join} />
      <Route path="/:room" component={JoinOrChat} />
    </Router>
  );
};

export default App;
