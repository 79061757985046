import React from "react";
import Join from "./components/Join/Join";
import Chat from "./components/Chat/Chat";
import { gl } from "./gl";

const JoinOrChat = (props) => {
  const room = props.match.params.room;

  if (gl.name) {
    return <Chat room={room} />;
  } else {
    return <Join room={room} />;
  }
};

export default JoinOrChat;
