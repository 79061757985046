import React from "react";
import "./Input.css";

const Input = ({ sendMessage, writingMessage }) => (
  <form className="form" onSubmit={sendMessage}>
    <input
      className="input"
      type="text"
      placeholder="Type a message..."
      onKeyDown={writingMessage}
    />
    <button className="sendButton" type="submit">
      Send
    </button>
  </form>
);

export default Input;
