import React from "react";
import ReactEmoji from "react-emoji";

import "./Message.css";

const Message = ({ message, name }) => {
  const { messageContent, emmiterName } = message;
  if (emmiterName === name) {
    return (
      <div className="messageContainer justifyEnd">
        <p className="sentText pr-10">{name}</p>
        <div className="messageBox backgroundBlue">
          <p className="messageText colorWhite">
            {ReactEmoji.emojify(messageContent)}
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="messageContainer justifyStart">
        <div className="messageBox backgroundLight">
          <p className="messageText colorDark">
            {ReactEmoji.emojify(messageContent)}
          </p>
        </div>
        <p className="sentText pl-10 ">{emmiterName}</p>
      </div>
    );
  }
};

export default Message;
