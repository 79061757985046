import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "./Join.css";
import { gl } from "../../gl";

const Join = (props) => {
  const showRoomInput = props.room === undefined;
  const initRoom = props.room ? props.room : gl.initRoom;
  const button = props.room ? `Join room ${props.room}` : "Connect";

  const [error, setError] = useState();
  const history = useHistory();

  useEffect(() => {
    console.log("useEffect - in");
    if (gl.error) {
      console.log("error", gl.error);
      setError(gl.error);
    }
  }, [error, props]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = e.target[0].value.trim();
    const room = e.target[1].value.trim() || initRoom;
    if (!name || !room) {
      gl.error = showRoomInput
        ? "Both fields are required"
        : "Please enter your name";
      setError(gl.error);
      return;
    }
    axiosRequest(name, room);
  };

  const axiosRequest = (name, room) => {
    const url = `${gl.serverURL}checkuser?name=${name}&room=${room}`;
    console.log("Axios request", url);
    axios.get(url).then((res) => {
      console.log("Axios response", res);
      const userRoomTaken = res.data;
      console.log("userRoomTaken", userRoomTaken);
      if (userRoomTaken) {
        gl.error = `It seems that there is already a user named ${name} in this room. Please try with another name.`;
        setError(gl.error);
      } else {
        gl.name = name;
        history.push(`/${room}`);
      }
    });
  };

  console.log("render", showRoomInput, error);
  return (
    <div className="joinOuterContainer">
      <div className="joinInnerContainer">
        <h1 className="heading">ChatBox</h1>
        <form onSubmit={handleSubmit}>
          {error && <div className="errorMessageJoin">{error}</div>}
          <div>
            <input
              defaultValue={gl.initName}
              placeholder="Name"
              className="joinInput"
              type="text"
            />
          </div>
          {showRoomInput && (
            <div>
              <input
                defaultValue={initRoom}
                placeholder="Room"
                className="joinInput mt-20"
                type="text"
              />
            </div>
          )}
          <button className="button mt-20" type="submit">
            {button}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Join;
