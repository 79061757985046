import React, { useState, useEffect } from "react";
import { useBeforeunload } from "react-beforeunload";
import { useHistory } from "react-router-dom";
import io from "socket.io-client";
import cloneDeep from "lodash/cloneDeep";

import "./Chat.css";
import InfoBar from "../InfoBar/InfoBar";
import Messages from "../Messages/Messages";
import Input from "../Input/Input";
import { gl } from "../../gl";

let socket;
let hidden = false;
let usersWritingTS = [];
let handleUsersWriting;
const connectionOptions = {
  transports: ["websocket"],
};
const audio = new Audio("juntos-607.mp3");

const Chat = (props) => {
  if (!socket) socket = io.connect(gl.serverURL, connectionOptions);
  const room = props.room;
  const initState = {
    messages: "",
    users: "",
    usersWriting: "",
  };
  const [state, setState] = useState(initState);
  const history = useHistory();

  const checkName = () => {
    gl.error = false;
    if (!gl.name) {
      gl.error = `Sorry, it's seems that your user name is not defined`;
      socket = undefined;
      history.push("/");
    }
  };
  checkName();
  const name = gl.name;

  useEffect(() => {
    console.log("useEffect chat - in");
    window.addEventListener("blur", onBlur);
    window.addEventListener("focus", onFocus);

    socket.on("connect", () => {
      onConnect();
    });

    socket.on("messageReceive", (data) => {
      onMessageReceive(data);
    });

    socket.on("writingMessageReceive", (data) => {
      onWritingMessageReceive(data);
    });

    return () => {
      if (socket) socket.off();
      window.removeEventListener("blur", onBlur);
      window.removeEventListener("focus", onFocus);
      console.log("useEffect chat - out");
    };
  });

  useBeforeunload(() => {
    const userId = gl.userId;
    emitUserLeaves(userId);
  });

  const onBlur = () => {
    hidden = true;
    console.log("hidden", hidden);
  };

  const onFocus = () => {
    hidden = false;
    console.log("hidden", hidden);
  };

  const onConnect = () => {
    console.log("Socket: connect " + socket.id);
    let userId;
    if (!gl.userId) {
      console.log("Socket: userId is not defined");
      gl.userId = socket.id;
      userId = gl.userId;
      emitJoin(userId);
    } else {
      userId = gl.userId;
      emitReconnect(userId);
    }
  };

  const emitJoin = (userId) => {
    console.log("emit join", userId, name, room);
    socket.emit("join", { userId, name, room }, (name) => {
      if (name) {
        gl.error = `It seems that there is already a user named ${name} in this room. Please try another name.`;
      } else {
        gl.error = `Sorry, it's seems that your user name is not defined`;
      }
      gl.userId = undefined;
      history.push("/");
    });
  };

  const emitReconnect = (userId) => {
    console.log("emit reconnect", userId);
    socket.emit("reconnect", { userId }, () => {
      gl.error = `Oops, sorry something went wrong :/`;
      gl.userId = undefined;
      socket = undefined;
      history.push("/");
    });
  };

  const emitMessageSend = (e) => {
    e.preventDefault();
    if (e.target[0].value === "") return;
    const message = {
      messageContent: e.target[0].value,
      emmiterName: name,
      emmiterRoom: room,
    };
    console.log("Sending message to server", message);
    socket.emit("messageSend", message, () => {
      gl.error = `Oops, sorry something went wrong :/`;
      socket = undefined;
      history.push("/");
    });
    console.log("Message sent");
    e.target[0].value = "";
  };

  const onMessageReceive = (data) => {
    console.log("Message received from server!", data);
    const msg = data.message;
    if (hidden) {
      console.log("playing notif sound");
      audio.play();
      try {
        var n = new Notification(msg.emmiterName, { body: msg.messageContent });
        n.onshow = function () {
          setTimeout(n.close.bind(n), gl.notificationTimeout);
        };
      } catch (error) {
        console.log(error);
      }
    }
    let newState = cloneDeep(state);
    newState.messages = [...newState.messages, msg];
    newState.usersWriting = [];
    usersWritingTS = [];
    if (data.users) newState.users = data.users;
    setState(newState);
  };

  const emitWritingMessageSend = (e) => {
    if (e.key !== "Enter") {
      socket.emit("writingMessageSend", { name, room });
    }
  };

  const onWritingMessageReceive = (name) => {
    const userWritingTS = { name, timeStamp: Date.now() };
    // console.log(userWritingTS, "is writing a message");
    const i = usersWritingTS.findIndex((elt) => elt.name === name);
    if (i !== -1) {
      usersWritingTS[i] = userWritingTS;
    } else {
      usersWritingTS.push(userWritingTS);
      let newState = cloneDeep(state);
      refreshUsersWriting(newState);
    }
    // console.log("usersWritingTS", usersWritingTS);
  };

  const refreshUsersWriting = (newState) => {
    const usersWriting = usersWritingTS.map((elt) => elt.name);
    newState.usersWriting = usersWriting;
    setState(newState);
  };

  handleUsersWriting = () => {
    let newState = cloneDeep(state);
    const TS = Date.now();
    let change = false;
    let i;
    do {
      i = usersWritingTS.findIndex(
        (elt) => TS - elt.timeStamp > gl.userWritingTimeout
      );
      if (i >= 0) {
        // console.log(usersWritingTS[i], "is not writing anymore");
        usersWritingTS.splice(i, 1);
        change = true;
      }
    } while (i >= 0);
    if (change) {
      refreshUsersWriting(newState);
    }
  };

  const emitUserLeaves = (userId) => {
    console.log("emit userLeaves", userId);
    socket.emit("userLeaves", { userId });
    socket.off();
  };

  const handleLeaveChat = () => {
    const userId = gl.userId;
    emitUserLeaves(userId);
    gl.userId = undefined;
    socket = undefined;
    history.push("/");
  };

  const getUsersWritingString = () => {
    let s;
    const a = state.usersWriting;
    if (a.length === 1) {
      s = a[0] + " is writing a message...";
    } else if (a.length === 2) {
      s = `${a[0]} and ${a[1]} are writing a message...`;
    } else if (a.length === 3) {
      s = `${a[0]}, ${a[1]} and ${a[2]} are writing a message...`;
    } else if (a.length > 3) {
      s = `${a[0]}, ${a[1]} and ${
        a.length - 2
      } others are writing a message...`;
    }
    return s;
  };

  console.log("render", state);
  return (
    <div className="outerContainer">
      <div className="container">
        <InfoBar
          room={room}
          users={state.users}
          handleLeaveChat={handleLeaveChat}
        />
        <Messages messages={state.messages} name={name} />
        {state.usersWriting.length > 0 && (
          <div className="usersWriting">{getUsersWritingString()}</div>
        )}
        <Input
          sendMessage={emitMessageSend}
          writingMessage={emitWritingMessageSend}
        />
      </div>
    </div>
  );
};

try {
  Notification.requestPermission();
} catch (error) {
  console.log(error);
}
setInterval(() => {
  if (handleUsersWriting) handleUsersWriting();
}, 500);

export default Chat;
