import React from "react";

import "./InfoBar.css";
import onlineIcon from "./icons/onlineIcon.png";
import closeIcon from "./icons/closeIcon.png";

const InfoBar = ({ room, users, handleLeaveChat }) => {
  let strUsers = "";
  if (users) {
    users = users.map((user) => user.name);
    const reducer = (acc, curVal) => acc + ", " + curVal;
    strUsers = users.reduce(reducer);
  }

  return (
    <div className="infoBar">
      <div className="leftInnerContainer">
        <img className="onlineIcon" src={onlineIcon} alt="online icon" />
        <h3>{room}</h3>
        <span className="infoBarSeparator">|</span>
        <span className="infoBarUsers">{strUsers}</span>
      </div>
      <div className="rightInnerContainer">
        <img src={closeIcon} alt="close icon" onClick={handleLeaveChat} />
      </div>
    </div>
  );
};

export default InfoBar;
